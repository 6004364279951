import * as React from 'react';
import {
  VStack,
  Heading,
  Text,
  Flex,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { Layout } from '../components/Layout';
import { SEO } from '../components/SEO';
import { useMixpanel } from 'gatsby-plugin-mixpanel';

const Privacy: React.FC = () => {
  const mixpanel = useMixpanel();
  React.useEffect(() => {
    mixpanel.track('Homepage: Viewed Privacy Policy');
  }, [mixpanel]);

  return (
    <Layout>
      <SEO title="Privacy policy" />
      <Flex direction="column" align="center" justify="center">
        <Heading size="lg" px={10} pt={10} textAlign="center">
          Canyou Privacy Policy
        </Heading>
        <VStack p={10} mx={5} spacing={4} maxWidth="900px">
          <Text>
            We understand that protecting your personal information is
            important. This Privacy Policy sets out our commitment to protecting
            the privacy of personal information provided to us, or otherwise
            collected by us when providing our platform Services to you. In this
            Privacy Policy we, us or our means API Software Pty Ltd t/a Canyou
            Software ABN 57 650 764 195.
          </Text>
          <Heading size="md" textAlign="center">
            Types of information
          </Heading>
          <Text>
            Personal information: is information or an opinion, whether true or
            not and whether recorded in a material form or not, about an
            individual who is identified or reasonably identifiable. Sensitive
            information: is a sub-set of personal information that is given a
            higher level of protection. Sensitive information means information
            relating to your racial or ethnic origin, political opinions,
            religion, trade union or other professional associations or
            memberships, philosophical beliefs, sexual orientation or practices,
            criminal records, health information or biometric information.
          </Text>
          <Heading size="md" textAlign="center">
            The information we collect
          </Heading>
          <Text>
            Personal information: The types of personal information we may
            collect about you include: As an end user (“member”) of our
            customer:
            <UnorderedList>
              <ListItem>your name; </ListItem>
              <ListItem>images of you; </ListItem>
              <ListItem>
                your contact details, including email address, mailing address,
                street address and/or telephone number;
              </ListItem>
              <ListItem>your age and/or date of birth; </ListItem>
              <ListItem>your preferences and/or opinions; </ListItem>
              <ListItem>
                information you provide to us through customer surveys; and
              </ListItem>
              <ListItem>sensitive information: </ListItem>
              <ListItem>
                if applicable, sensitive information you include on your resume
                or in your application for a job; and
              </ListItem>
              <ListItem>
                any other sensitive information you choose to upload to our
                Services. We will not collect sensitive information about you
                without first obtaining your consent.
              </ListItem>
            </UnorderedList>
            As a representative or employee of our customer:
            <UnorderedList>
              <ListItem>your name; </ListItem>
              <ListItem>
                your work contact details, including email address, mailing
                address and/or telephone number;
              </ListItem>
              <ListItem>your role within your organisation; </ListItem>
              <ListItem>
                your credit card or payment details (through our third party
                payment processor);
              </ListItem>
              <ListItem>
                details of products and services we have provided to you and/or
                that you have enquired about, and our response to you;
              </ListItem>
            </UnorderedList>
            As a user of our website or online platform:
            <UnorderedList>
              <ListItem>
                your browser session and geo-location data, device and network
                information, statistics on page views and sessions, acquisition
                sources, search queries and/or browsing behaviour;
              </ListItem>
              <ListItem>
                information about your access and use of our Services, including
                through the use of Internet cookies, your communications with
                our online Services, the type of browser you are using, the type
                of operating system you are using and the domain name of your
                Internet service provider;
              </ListItem>
              <ListItem>additional personal</ListItem>
              information that you provide to us, directly or indirectly,
              through your use of our Services, associated applications,
              associated social media platforms and/or accounts from which you
              permit us to collect information; and
              <ListItem>
                any other personal information requested by us and/or provided
                by you or a third party.
              </ListItem>
            </UnorderedList>
          </Text>
          <Heading size="md" textAlign="center">
            How we collect personal information
          </Heading>
          <Text>
            We collect personal information in a variety of ways, including:
            <UnorderedList>
              <ListItem>
                Directly: We collect personal information which you directly
                provide to us, including when you register for an account,
                through the ‘contact us’ form on our website or when you request
                our assistance via email, our online chat or over the telephone.
              </ListItem>
              <ListItem>
                Indirectly: We may collect personal information which you
                indirectly provide to us while interacting with us, such as when
                you use our website, in emails, over the telephone and in your
                online enquiries, and when you choose to upload information to
                our Platform, including when you upload licences,
                certifications, and qualifications.
              </ListItem>
              <ListItem>
                From third parties: We collect personal information from third
                parties, such as details of your use of our website from our
                analytics and cookie providers and marketing providers. See the
                “Cookies” section below for more detail on the use of cookies.
              </ListItem>
            </UnorderedList>
          </Text>
          <Heading size="md" textAlign="center">
            Collection and use of personal information
          </Heading>
          <Text>
            Personal information: We may collect, hold, use and disclose
            personal information for the following purposes:
            <UnorderedList>
              <ListItem>
                to enable you to access and use our Services, including to
                provide you with a login;
              </ListItem>
              <ListItem>
                to provide our Services to you, including to enable to use our
                workflow management tool, whether as a business or a member;
              </ListItem>
              <ListItem>
                to enable you to access and use our associated applications and
                associated social media platforms;
              </ListItem>
              <ListItem>
                to contact and communicate with you about our Services;
              </ListItem>
              <ListItem>
                for internal record keeping, administrative, invoicing and
                billing purposes;
              </ListItem>
              <ListItem>
                for analytics, market research and business development,
                including to operate and improve our Services, associated
                applications and associated social media platforms;
              </ListItem>
              <ListItem>
                to run promotions, competitions and/or offer additional benefits
                to you;
              </ListItem>
              <ListItem>
                for advertising and marketing, including to send you promotional
                information about our products and services and information that
                we consider may be of interest to you;
              </ListItem>
              <ListItem>
                to comply with our legal obligations and resolve any disputes
                that we may have;
              </ListItem>
              <ListItem>
                if you have applied for employment with us; to consider your
                employment application; and
              </ListItem>
              <ListItem>
                if otherwise required or authorised by law. Sensitive
                information: We only collect, hold, use and disclose sensitive
                information for the following purposes:
              </ListItem>
              <ListItem> any purposes you consent to; </ListItem>
              <ListItem>
                the primary purpose for which it is collected, for instance, to
                upload your documentation to relevant third parties where you
                use our Services for that reason;
              </ListItem>
              <ListItem>
                secondary purposes that are directly related to the primary
                purpose for which it was collected, including disclosure to the
                below listed third parties as reasonably necessary to provide
                our Services to you;
              </ListItem>
              <ListItem>
                to contact emergency services, or to speak with your family,
                partner or support person where we reasonably believe there is a
                serious risk to the life, health or safety of you or another
                person and it is impracticable for us to obtain your consent;
                and
              </ListItem>
              <ListItem> if otherwise required or authorised by law.</ListItem>
            </UnorderedList>
          </Text>
          <Heading size="md" textAlign="center">
            Disclosure of personal information to third parties
          </Heading>
          <Text>
            We may disclose personal information to:
            <UnorderedList>
              <ListItem>
                third party service providers for the purpose of enabling them
                to provide their services, to us, including (without limitation)
                the third parties we integrate with, including Xero, IT service
                providers, data storage, web-hosting and server providers, debt
                collectors, couriers, maintenance or problem-solving providers,
                marketing or advertising providers, professional advisors and
                payment systems operators;
              </ListItem>
              <ListItem>
                our employees, contractors and/or related entities;
              </ListItem>
              <ListItem>
                our existing or potential agents or business partners;
              </ListItem>
              <ListItem>
                sponsors or promoters of any promotions or competition we run;
              </ListItem>
              <ListItem>
                anyone to whom our business or assets (or any part of them) are,
                or may (in good faith) be, transferred;
              </ListItem>
              <ListItem>
                courts, tribunals and regulatory authorities, in the event you
                fail to pay for goods or services we have provided to you;
              </ListItem>
              <ListItem>
                courts, tribunals, regulatory authorities and law enforcement
                officers, as required or authorised by law, in connection with
                any actual or prospective legal proceedings, or in order to
                establish, exercise or defend our legal rights;
              </ListItem>
              <ListItem>
                third parties to collect and process data, such as Google
                Analytics (To find out how Google uses data when you use third
                party websites or applications, please see
                www.google.com/policies/privacy/partners/ or any other URL
                Google may use from time to time), Facebook Pixel or other
                relevant analytics businesses; and
              </ListItem>
              <ListItem>
                any other third parties as required or permitted by law, such as
                where we receive a subpoena. Overseas disclosure: Where we
                disclose your personal information to third parties listed
                above, these third parties may store, transfer or access
                personal information outside of Australia.
              </ListItem>
            </UnorderedList>
          </Text>
          <Heading size="md" textAlign="center">
            Your rights and controlling your personal information
          </Heading>
          <Text>
            Your choice: Please read this Privacy Policy carefully. If you
            provide personal information to us, you understand we will collect,
            hold, use and disclose your personal information in accordance with
            this Privacy Policy. You do not have to provide personal information
            to us, however, if you do not, it may affect our ability to provide
            our Services to you and your use of our Services. Information from
            third parties: If we receive personal information about you from a
            third party, we will protect it as set out in this Privacy Policy.
            If you are a third party providing personal information about
            somebody else, you represent and warrant that you have such person’s
            consent to provide the personal information to us. Restrict and
            unsubscribe: To object to processing for direct
            marketing/unsubscribe from our email database or opt-out of
            communications (including marketing communications), please contact
            us using the details below or opt-out using the opt-out facilities
            provided in the communication. Access: You may request access to the
            personal information that we hold about you. An administrative fee
            may be payable for the provision of such information. Please note,
            in some situations, we may be legally permitted to withhold access
            to your personal information. Correction: If you believe that any
            information we hold about you is inaccurate, out of date,
            incomplete, irrelevant or misleading, please contact us using the
            details below. We will take reasonable steps to promptly correct any
            information found to be inaccurate, out of date, incomplete,
            irrelevant or misleading. Please note, in some situations, we may be
            legally permitted to not correct your personal information.
            Complaints: If you wish to make a complaint, please contact us using
            the details below and provide us with full details of the complaint.
            We will promptly investigate your complaint and respond to you, in
            writing, setting out the outcome of our investigation and the steps
            we will take in response to your complaint. You also have the right
            to contact the relevant privacy authority.
          </Text>
          <Heading size="md" textAlign="center">
            Storage and security
          </Heading>
          <Text>
            We are committed to ensuring that the personal information we
            collect is secure. In order to prevent unauthorised access or
            disclosure, we have put in place suitable physical, electronic and
            managerial procedures, to safeguard and secure personal information
            and protect it from misuse, interference, loss and unauthorised
            access, modification and disclosure. While we are committed to
            security, we cannot guarantee the security of any information that
            is transmitted to or by us over the Internet. The transmission and
            exchange of information is carried out at your own risk.
          </Text>
          <Heading size="md" textAlign="center">
            Cookies
          </Heading>
          <Text>
            We may use cookies on our online Services from time to time. Cookies
            are text files placed in your computer&apos;s browser to store your
            preferences. Cookies, by themselves, do not tell us your email
            address or other personally identifiable information. If and when
            you choose to provide our online Services with personal information,
            this information may be linked to the data stored in the cookie. You
            can block cookies by activating the setting on your browser that
            allows you to refuse the setting of all or some cookies. However, if
            you use your browser settings to block all cookies (including
            essential cookies) you may not be able to access all or parts of our
            online Services.
          </Text>
          <Heading size="md" textAlign="center">
            Links to other websites
          </Heading>
          <Text>
            Our Services may contain links to other websites. We do not have any
            control over those websites and we are not responsible for the
            protection and privacy of any personal information which you provide
            whilst visiting those websites. Those websites are not governed by
            this Privacy Policy.
          </Text>
          <Heading size="md" textAlign="center">
            Amendments
          </Heading>
          <Text>
            We may, at any time and at our discretion, vary this Privacy Policy
            by publishing the amended Privacy Policy on our website. We
            recommend you check our website regularly to ensure you are aware of
            our current Privacy Policy.
          </Text>
          <Text>
            For any questions or notices, please contact our Privacy Officer at:
            API Software Pty Ltd t/a Canyou Software ABN 57 650 764 195 Email:
            support@canyou.com.au
          </Text>
          <small>Last update: 7 June 2021 ©</small>
        </VStack>
      </Flex>
    </Layout>
  );
};

export default Privacy;
